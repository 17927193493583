
import { useTranslation } from 'react-i18next';
import Transition from '../../components/Transition/Transition';
import React from 'react'
import {Avatar, Link, Badge, Divider, Grid, Fade, Stack,Pagination, Typography,Box, Button, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import {} from "@mui/material";
import collections from "../../assets/data/collection.json";
import PageHeader from '../../components/PageHeader/PageHeader';

import { useState, useEffect } from "react";
import CollectionCarousel from '../../components/CollectionCarousel/CollectionCarousel';
import { useLocalStorage } from "../../components/Localstorage/useLocalStorage";
import CloseIcon from '@mui/icons-material/Close';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import DeleteIcon from '@mui/icons-material/Delete';
import './Collections.css';
import {makeStyles} from '@mui/styles';
const useStyles = makeStyles(({
  outer: {
    transition: "0.4s",
    '&:hover': {
      background: "#FF0000", 
      transform: "translate(0, -8px)",
      "& .inner": {
        color: "#FFFFFF"
      }
    },
  },
}));
function Collections() 
{
  const classes = useStyles();
  
  const {t} = useTranslation();
  const [addedList, setAddedList] = useLocalStorage("addedList", []);
  const [open, openchange] = useState(false);
  const [checkout, checkoutChange] = useState(false);
  const [itemList, setItemList] = useState(collections);
  const [showList, setShowList] = useState(collections.slice(0,24));
  const [collectionDetail, setCollectionDetail] = useState(collections[0]);

  const [totalPage, setTotalPage] = useState(Math.ceil(collections.length/24.0));
  const [page, setPage] = React.useState(1);
  let filters= ["Collection.Catergories.Head","Collection.Catergories.Ladies","Collection.Catergories.Man", "Collection.Catergories.Child", "Collection.Catergories.Props",  "Collection.Catergories.Sequin", "Collection.Catergories.MasterLam"]
  const [selectedFilters, setSelectedFilters] = useState([]);
  const address = "https://docs.google.com/forms/d/e/1FAIpQLSdGKVQW6J6hnThPUf1HGU4yCzRycj3dguHuK712L8ekxDn2qg/viewform?usp=pp_url&entry.361822754=";

  const deleteFromList = (coll) =>
  {
    let newArray = addedList.filter(item => item!== coll);
    setAddedList([...newArray]); 
  }
  const goGoogleForm = () =>
  {
    let link = address;
    addedList.map((name=>{
      link = link + name + ",";
      return;
    }))
    window.open(link, "_blank");
    
  }
  const openCollection= (coll)=>
  {
    setCollectionDetail(coll)
    openchange(true);
  }

  const popupCheckout = ()=>
  {
    checkoutChange(true);
  }
  const closeCollection = ()=>
  {
    openchange(false);
  }

  const closeCheckout = ()=>
    {
      checkoutChange(false);
    }

  const handleChange = (event, value) => {
    setPage(value);
    setShowList(itemList.slice((value-1)*24, (value-1)*24+24))
  };

const dialogAddList = (name) =>
{
  if(!addedList.includes(name))
  {
    setAddedList([...addedList, name]);
  }
  
  openchange(false);
}

const addList = (name) =>
  {
    if(!addedList.includes(name))
      {
    setAddedList([...addedList, name]);
      }
  }
const handleClick = (idx, selectedCategory) =>
{
  if(selectedFilters.includes(selectedCategory))
  {
    let filterss = selectedFilters.filter((el) => el !== selectedCategory);
    setSelectedFilters(filterss);
  }
  else
  {
    setSelectedFilters([...selectedFilters, selectedCategory]);
  }
}


const clearShowList = () =>
{
  setShowList([]);
}

const clearAddedList = () =>
  {
    setAddedList([]);
  }

useEffect(() =>
{
  filterItems();

}, [selectedFilters]);

const filterItems = ()=>
{
  if(selectedFilters.length>0)
  {
    let tempItems = selectedFilters.map((selectedCategory) => {
      let temp = collections.filter((collection)=>collection.catergories === selectedCategory);
      return temp;
    });
    clearShowList();
    setItemList(tempItems.flat());
    setTotalPage(Math.ceil(tempItems.flat().length/24.0));
    setPage(1);
    setShowList(tempItems.flat().slice(0, 20))
  }
  else
  {
    clearShowList();
    setItemList([...collections]);
    setTotalPage(Math.ceil(collections.flat().length/24.0));
    setPage(1);
    setShowList(collections.flat().slice(0, 24))

  }
}
  return (
    
    <Stack direction="row" sx={{justifyContent:"center", minHeight:"65.5vh", backgroundColor:"background.main"}}>
      <Dialog open={open} onClose={closeCollection} fullWidth maxWidth={false}>
        <DialogTitle> <Stack width="100%" direction="row" justifyContent="flex-end"><IconButton ><CloseIcon onClick={closeCollection} style={{color:"#ee0000"}}></CloseIcon></IconButton></Stack></DialogTitle>
        <DialogContent>
          
          
        <Grid container direction="row" >
          <Grid item lg={7.5} md={12} xs={12}  marginTop={{lg:"0px", md:"0px", xs:"0px"}} >
            <Stack direction="column" margin={0} spacing={0} justifyContent="flex-start">
              <CollectionCarousel image={collectionDetail.image}/>
            </Stack>
          </Grid>
          <Grid item lg={4.5} md={12} xs={12}  >
            <Stack direction="column" height="100%" margin={0} spacing={0} justifyContent="space-between">
              <Stack direction="column">
            <Typography sx={{fontWeight:"600", color:"#000000", fontSize:{lg:"30px", md: "18px", xs:"18px"}}}>{collectionDetail.name}</Typography>
            <Typography sx={{fontWeight:"400", color:"primary.grey", fontSize:{lg:"24px", md: "18px", xs:"18px"}}}>{t(collectionDetail.catergories)}</Typography>
            </Stack>
            <Stack direction="column">
            <Button variant="contained" disabled={collectionDetail.isRental=="Yes"?false:true} onClick={() => dialogAddList(collectionDetail.name)} sx={{height:{lg:"40px", md:"45px", xs:"35px"},
            
  bgcolor:"#ee0000",
  borderRadius:"10px",
  
  color:'#ffffff',
  textTransform: 'none',
  fontSize:{lg:"18px", md:"18px", xs:"2.7vw"},
  fontWeight:"600",
  "&:hover": {
    bgcolor:"#ffffff",
    borderRadius:"10px",
    color:'#ee0000',
    
  },
}} 
>{t("Collection.function.AddList")}</Button>
            
            </Stack></Stack>
          </Grid>
        </Grid>   
        </DialogContent>

      </Dialog>
      <Dialog open={checkout} onClose={closeCheckout} fullWidth maxWidth={false}>
        <DialogTitle> 
          <Stack width="100%" direction="row" justifyContent="space-between"> 
          <Typography sx={{fontWeight:"600", color:"#000000", fontSize:{lg:"30px", md: "18px", xs:"18px"}}}>{addedList.length}&nbsp;{t("Collection.function.RentalList-2")}</Typography>
            <IconButton ><CloseIcon onClick={closeCheckout} style={{color:"#ee0000"}}></CloseIcon></IconButton>
            </Stack>
            </DialogTitle>
        <DialogContent>
          <Stack width="100%" direction="column" spacing="5px" divider={<Divider orientation="horizontal" flexItem />}>
        {addedList.map((name, idx) =>
            {
              let coll = collections.find((collection)=>{
                return collection.name === name;
              });
              return(
              <Grid container direction="row" spacing={{lg:"20px", md:"15px", xs:"5px"}} >
              <Grid item lg={3} md={4} xs={4}  marginTop={{lg:"0px", md:"0px", xs:"0px"}} > 
                <Stack direction="row" justifyContent="center" width="100%">
              <Box component="img"  sx={{maxHeight: {lg:"20vh",md:"auto", xs:"auto"}, display: 'block', maxWidth:  {lg:"auto",md:"auto", xs:"20vw"}, overflow: 'hidden'}} src={coll.thumbnail} alt={coll.thumbnail}/></Stack>
              </Grid>
              <Grid item lg={8} md={7} xs={7}  >
                            <Stack direction="column">
                <Typography noWrap={true} sx={{fontWeight:"600", color:"#000000", fontSize:{lg:"24px", md: "18px", xs:"16px"}}}>{coll.name}</Typography>
                <Typography sx={{fontWeight:"400", color:"primary.grey", fontSize:{lg:"18px", md: "18px", xs:"14px"}}}>{t(coll.catergories)}</Typography>
                </Stack>
              </Grid>
              <Grid item lg={1} md={1} xs={1} >       
                <Stack direction="row" justifyContent="flex-end">
                <IconButton onClick={()=>deleteFromList(coll.name)}   sx={{outline: 'none'}}>
 
 <Avatar  sx={{ height:{lg:"30px", md:"30px", xs:"30px"}, width:{lg:"30px", md:"30px", xs:"30px"}, bgcolor: "#ffffff" }}>
      <DeleteIcon   sx={{  width:{lg:"22.5px", md:"3vw", xs:"22.5px"},color: 'primary.red' }} />
  </Avatar>
    </IconButton></Stack>

                </Grid>
            </Grid>   )
            }

)
}
<Stack direction="row"  justifyContent="space-evenly" spacing={{lg:"20px", md:"10px", xs:"1vw"}}>

<Button
variant="contained"
onClick={clearAddedList}
sx={{
width:{lg:"45%", md:"50%", xs:"50%"},
height:{lg:"40px", md:"45px", xs:"35px"},
bgcolor:"#ee0000",
borderRadius:"10px",
color:'#ffffff',
textTransform: 'none',
fontSize:{lg:"18px", md:"18px", xs:"2.7vw"},
fontWeight:"600",
"&:hover": {
bgcolor:"#ffffff",
borderRadius:"10px",
color:'#ee0000',
transform: 'scale(1.05)',
},
}}
>
{t("Collection.function.ClearList")}
</Button>
<Button
onClick={goGoogleForm}
variant="contained"


sx={{
width:{lg:"45%", md:"50%", xs:"50%"},
height:{lg:"40px", md:"45px", xs:"35px"},
bgcolor:"#ee0000",
borderRadius:"10px",

color:'#ffffff',
textTransform: 'none',
fontSize:{lg:"18px", md:"18px", xs:"2.7vw"},
fontWeight:"600",
"&:hover": {
bgcolor:"#ffffff",
borderRadius:"10px",
color:'#ee0000',
transform: 'scale(1.05)',
},
}}
>
{t("Collection.function.ContinueFillForm")}
</Button>
</Stack>

</Stack>

        </DialogContent>

      </Dialog>
      <Fade in={true} timeout={2000}>

        <Stack direction="column"  height="100%" width={{ld:"80%", md:"90%", xs:"95%"}} marginBottom={10}>
          <PageHeader weight={t("NavBar.Collections.length")}>{t("NavBar.Collections.title")}</PageHeader>
           <Stack direction="row" flexWrap="wrap" justifyContent="center" width="100%" marginBottom="20px" spacing="0.5vw" rowGap={2} >

            {filters.map((filter, idx) =>
            (
<Button marginBottom="100px"
      variant="contained"
      className={selectedFilters?.includes(filter) ? "active":"notactive"}
      onClick={() => handleClick(idx, filter)}
      
      sx={{ bgcolor:selectedFilters.includes(filter)?  "#ee0000":"#ffffff",
        color:selectedFilters.includes(filter)?  "#ffffff":"#ee0000",
        width:{lg:"220px", md:"200px", xs:"23vw"},
        height:{lg:"40px", md:"45px", xs:"4.5vh"},
       
        borderRadius:"10px",
        
        textTransform: 'none',
        fontSize:{lg:"18px", md:"18px", xs:"2.8vw"},
        fontWeight:"600",
        "&:hover": {
          bgcolor:"#ee0000",
          borderRadius:"10px",
          color:'#ffffff',
          transform: 'scale(1.1)',

        }       
      }}
    >
      {t(filter)}
    </Button>
            ))}
          </Stack>
          <Stack direction="row" display={{md:"flex", xs:"none"}} width="100%" justifyContent="center" marginBottom="20px">
         
          <Pagination size="large" page={page} count={totalPage} onChange={handleChange} variant="outlined" color="primary" />
</Stack>
<Stack direction="row" width="100%" display={{xs:"flex", md:"none"}} justifyContent="center" marginBottom="20px">
          <Pagination  page={page} count={totalPage} onChange={handleChange} variant="outlined" color="primary" />
</Stack>
          <Grid container directon="row" justifyContent="center" width="100%" spacing={1} marginBottom={3}>


{
 
 showList.map((collection)=>{
            return (

<Grid item lg={3} md={4} xs={6} width="100%" >
<Box  width={{lg:"22vw" , md:"29vw",xs:"46vw"}} sx={{ bgcolor:"#ffffff", boxShadow: 3, borderRadius:"5px" }}>
        <Stack width="auto" direction="column" spacing={0.5} padding="10px">
        <Box component="img"  onClick={()=>openCollection(collection)} height={{lg:"15vw", md:"19vw", xs:"31vw"}} sx={{objectFit:'cover', display: 'block', width: "100%", overflow: 'hidden'}} src={collection.thumbnail} alt={collection.thumbnail}/>
        <Stack direction="column" width="100%">
          <Typography noWrap={true} sx={{fontWeight:"400", color:"#000000", fontSize:{lg:"18px", md: "18px", xs:"18px"}}}>{collection.name}</Typography>
        <Typography sx={{fontWeight:"400", color:"primary.grey", fontSize:{lg:"18px", md: "18px", xs:"18px"}}}>{t(collection.catergories)}</Typography>
        
        </Stack>

        <Stack direction="row" marginLeft={{lg:"0px", md:"0px", xs:"20px"}} spacing={{lg:"10px", md:"10px", xs:"1vw"}}>

                    <Button
      variant="contained"
      onClick={()=>openCollection(collection)}
      sx={{
        width:{lg:"140px", md:"160px", xs:"35vw"},
        height:{lg:"40px", md:"45px", xs:"35px"},
        bgcolor:"#ee0000",
        borderRadius:"10px",
        color:'#ffffff',
        textTransform: 'none',
        fontSize:{lg:"18px", md:"18px", xs:"2.7vw"},
        fontWeight:"600",
        "&:hover": {
          bgcolor:"#ffffff",
          borderRadius:"10px",
          color:'#ee0000',
          transform: 'scale(1.1)',
        },
      }}
    >
      {t("Collection.function.LearnMore")}
    </Button>
    <Button

variant="contained"
onClick={()=>addList(collection.name)}
disabled={collection.isRental=="Yes"?false:true}
sx={{
  width:{lg:"140px", md:"160px", xs:"35vw"},
  height:{lg:"40px", md:"45px", xs:"35px"},
  bgcolor:"#ee0000",
  borderRadius:"10px",
  
  color:'#ffffff',
  textTransform: 'none',
  fontSize:{lg:"18px", md:"18px", xs:"2.7vw"},
  fontWeight:"600",
  "&:hover": {
    bgcolor:"#ffffff",
    borderRadius:"10px",
    color:'#ee0000',
    transform: 'scale(1.1)',
  },
}}
>
{t("Collection.function.AddList")}
</Button>
                </Stack>

      </Stack>
    </Box>
               </Grid> 
            )
        })
       

          }

          </Grid>
          <Stack direction="row" display={{md:"flex", xs:"none"}} width="100%" justifyContent="center">
          <Pagination size="large" page={page} count={totalPage} onChange={handleChange} variant="outlined" color="primary" />
</Stack>
<Stack direction="row" width="100%" display={{xs:"flex", md:"none"}} justifyContent="center">
          <Pagination  page={page} count={totalPage} onChange={handleChange} variant="outlined" color="primary" />
</Stack> 
<Link onClick={popupCheckout} position="fixed" bottom="45px" right="40px" sx={{outline: 'none'}}>
 
 <Avatar className={classes.outer} sx={{ height:{lg:"55px", md:"5vw", xs:"55px"}, width:{lg:"55px", md:"5vw", xs:"55px"}, bgcolor: "#ffffff" }}>
    <Badge  badgeContent={addedList.length} color="primary">
      <CheckroomIcon  className="inner" sx={{  width:{lg:"22.5px", md:"3vw", xs:"22.5px"},color: 'primary.red' }} />
  </Badge >
  </Avatar>
    </Link>


        </Stack>
      </Fade>


    </Stack>
  )
}export default Transition(Collections);