import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, Paper, Modal, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { FaChevronLeft, FaChevronRight, FaExpand } from "react-icons/fa";

const SliderContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "80vh",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "50vh",
  },
}));

const SlideImage = styled("img")({
  width : "100%",
  height: "100%",
  objectFit: "contain",
});

const NavigationButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
    width: "5vh",
  height: "5vh",
  transform: "translateY(-50%)",
  color:"#ee0000",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
});

const DotsContainer = styled(Box)({
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: "8px",
});

const Dot = styled(Box)(({ active }) => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: active ? "#ee0000" : "rgba(221, 221, 221, 0.5)",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
}));

const ExpandButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
  color:"rgba(255, 255, 255, 0.8)",
  backgroundColor: "#ee0000",
  "&:hover": {
    backgroundColor: "#dd0000",
  },
});



const CollectionCarousel = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % props.image.length);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  const handlePrevious = () => {
    setCurrentSlide((prev) => (prev - 1 + props.image.length) % props.image.length);
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % props.image.length);
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box sx={{ position: "static"}}>
      <SliderContainer>
        <SlideImage
          src={props.image[currentSlide]}
          alt={props.image[currentSlide]}
        />

        <NavigationButton
          onClick={handlePrevious}
          sx={{ left: "10px" }}
          aria-label="Previous slide"
        >
          <FaChevronLeft />
        </NavigationButton>

        <NavigationButton
          onClick={handleNext}
          sx={{ right: "10px" }}
          aria-label="Next slide"
        >
          <FaChevronRight />
        </NavigationButton>

        <ExpandButton
          onClick={() => setModalOpen(true)}
          aria-label="Expand image"
        >
          <FaExpand />
        </ExpandButton>



        <DotsContainer>
          {props.image.map((_, index) => (
            <Dot
              key={index}
              active={currentSlide === index}
              onClick={() => handleDotClick(index)}
            />
          ))}        </DotsContainer>
      </SliderContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="image-modal"
      >
        <Box
          sx={{
            position: {lg:"absolute",md:"relative", xs:"relative"},
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            maxWidth: "100vw",
            maxHeight: "95vh",
            bgcolor: "treansparent",
            boxShadow: 24,
            p: 1,
          }}
        >
          <img
            src={props.image[currentSlide]}
            alt={props.image[currentSlide]}
            style={{ maxWidth: "95vw", maxHeight: "95vh", objectFit: "contain" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default CollectionCarousel;